<template>
  <v-app
    class="backdrop"
  >
    <v-app-bar
      app
      clipped-left
    >
      <div class="d-flex align-center">

        <v-app-bar-nav-icon
          v-if="$store.state.auth.authenticated"
          @click="drawer = !drawer"
        ></v-app-bar-nav-icon>

        <v-img
          alt="Khula Logo"
          src="@/assets/weblogo.png"
        />

      </div>

      <v-spacer></v-spacer>

      <v-menu 
        v-if="$store.state.auth.authenticated && ($vuetify.breakpoint.xs || $vuetify.breakpoint.sm)"
        offset-y
      >

        <template v-slot:activator="{ on, attrs }">
          <v-btn             
            fab
            small 
            class="ma-2 btn"
            v-bind="attrs" 
            v-on="on"
          >
            <v-icon>
              mdi-account
            </v-icon>             
          </v-btn>
        </template>

        <v-list>
          <v-list-item @click="signOut" style="min-width:150px">
            <v-icon>
              mdi-logout
            </v-icon>             
            &nbsp;Sign Out
          </v-list-item>
        </v-list>

      </v-menu>

      <v-menu 
        v-if="$store.state.auth.authenticated && ($vuetify.breakpoint.md || $vuetify.breakpoint.lg || $vuetify.breakpoint.xl)"
        offset-y
      >

        <template v-slot:activator="{ on, attrs }">
          <v-btn 
            rounded
            class="ma-2 btn" 
            v-bind="attrs" v-on="on">
            {{ $store.state.auth.user.firstname + ' ' + $store.state.auth.user.surname }}
            <v-icon
              right
              dark
            >
              mdi-menu-down
            </v-icon>             
          </v-btn>
        </template>

        <v-list>
          <v-list-item @click="signOut" style="min-width:150px">
            <v-icon>
              mdi-logout
            </v-icon>             
            &nbsp;Sign Out
          </v-list-item>
        </v-list>

      </v-menu>

    </v-app-bar>
    
    <v-navigation-drawer 
      v-if="$store.state.auth.authenticated"
      v-model="drawer"
      app
      clipped
    >
      <v-list 
        nav
      >

        <!-- Leads -->

        <v-list-item>
          <v-btn
            block
            rounded
            to="/leads"
          >
            <v-icon left>
              mdi-account-filter
            </v-icon>
            Leads  
            <v-spacer />        
          </v-btn>
        </v-list-item> 

        <!-- Learners -->

        <v-list-item>
          <v-btn
            block
            rounded
            to="/learners"
          >
            <v-icon left>
              mdi-account-star
            </v-icon>
            Learners  
            <v-spacer />        
          </v-btn>
        </v-list-item> 

        <!-- Guardians -->

        <v-list-item>
          <v-btn
            block
            rounded
            to="/guardians"
          >
            <v-icon left>
              mdi-account-multiple
            </v-icon>
            Guardians  
            <v-spacer />        
          </v-btn>
        </v-list-item> 

        <!-- Tutors -->

        <v-list-item>
          <v-btn
            block
            rounded
            to="/tutors"
          >
            <v-icon left>
              mdi-account-school
            </v-icon>
            Tutors  
            <v-spacer />        
          </v-btn>
        </v-list-item> 

        <!-- Rubrics -->

        <v-list-item>
          <v-btn
            block
            rounded
            to="/rubrics"
          >
            <v-icon left>
              mdi-note-text
            </v-icon>
            Rubrics
            <v-spacer />        
          </v-btn>
        </v-list-item> 

      </v-list>
    </v-navigation-drawer>

    <v-main>
      <router-view :key="$route.fullPath"></router-view>
    </v-main>

  </v-app>
</template>

<script>
//import { RepositoryFactory } from '@/repositories/RepositoryFactory'

//const FeedbackRepository = RepositoryFactory.get('feedback');

export default {
  name: 'App',

  data: () => ({
    drawer: true,
  }),

  methods: {

    signOut() {
      this.$store.commit('signOut'); 
      this.$router.push("/");
    },
    
  },

};
</script>

<style scoped>
  .backdrop {
    background-color: #eeeeee;
  }

</style>