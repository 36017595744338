import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
  
  {
    path: '/',
    component: () => import('@/views/SignIn'), 
    meta: {
      requiresAuth: false
    },        
  },

  { 
    path: '/addlead', 
    component: () => import('@/views/AddLead'), 
    meta: {
      requiresAuth: true
    },    
  },

  { 
    path: '/captureassessment/:assessmentid', 
    component: () => import('@/views/CaptureAssessment'), 
    meta: {
      requiresAuth: true
    },    
  },

  { 
    path: '/guardians', 
    component: () => import('@/views/Guardians'), 
    meta: {
      requiresAuth: true
    },    
  },

  { 
    path: '/leads', 
    component: () => import('@/views/Leads'), 
    meta: {
      requiresAuth: true
    },    
  },

  { 
    path: '/learners', 
    component: () => import('@/views/Learners'), 
    meta: {
      requiresAuth: true
    },    
  },

  { 
    path: '/registerguardian', 
    component: () => import('@/views/RegisterGuardian'), 
    meta: {
      requiresAuth: true
    },    
  },

  { 
    path: '/registerlearner', 
    component: () => import('@/views/RegisterLearner'), 
    meta: {
      requiresAuth: true
    },    
  },

  { 
    path: '/registertutor', 
    component: () => import('@/views/RegisterTutor'), 
    meta: {
      requiresAuth: true
    },    
  },

  { 
    path: '/rubrics', 
    component: () => import('@/views/Rubrics'), 
    meta: {
      requiresAuth: true
    },    
  },

  { 
    path: '/tutors', 
    component: () => import('@/views/Tutors'), 
    meta: {
      requiresAuth: true
    },    
  },

  { 
    path: '/updatelead/:leadid', 
    component: () => import('@/views/UpdateLead'), 
    meta: {
      requiresAuth: true
    },    
  },

  { 
    path: '/viewrubric/:rubricid', 
    component: () => import('@/views/ViewRubric'), 
    meta: {
      requiresAuth: true
    },    
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if ( to.matched.some(record => record.meta.requiresAuth) ) {
    
    // this route requires auth, check if logged in
    // if not, redirect to sign in page.
    if (!store.state.auth.authenticated) {
      next({
        path: '/',
        query: { redirect: to.fullPath }
      })
    } else {
      next()
    }

  } else {
    if (to.path == '/') {
      if (store.state.auth.authenticated) {
        next({
          path: '/learners',
          query: { redirect: to.fullPath }
        })        
      } else {
        next()
      }
    } else {
      next() // make sure to always call next()!
    }
  }
});

export default router
